// core
import React, {useEffect, useState} from 'react';

// library
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {useDispatch, useSelector} from "react-redux";


//components
import {DspTable} from "./components/DspTable/DspTable";
import {SiteSetupTable} from "./components/SiteSetupTable/SiteSetupTable";
import {server} from "../../../REST";

import {siteSetupActions} from "../../../bus/siteSetup/actions";
import {errorsActions} from "../../../bus/errors/actions";
import {statusActions} from "../../../bus/status/actions";
import {mysteryActions} from "../../../bus/mystery/actions";
import {getSiteSetup} from "../../../bus/siteSetup/selectors";
import {getErrors} from "../../../bus/errors/selectors";
import {getStatus} from "../../../bus/status/selectors";


//styles
import styles from './SiteSetup.module.scss';

const DSP = {
    siteName: {label: 'Site name', type: 'text', value: 'Q-SYS'},
    dsp: {label: 'DSP', type: 'select', value: ['Q-SYS', 'Symetrix', 'Tesira', 'Xilica']},
    DspID: {label: 'ID', type: 'text', value: '0000'},
};

const tabsOptions = {
    symetrix: {
        faders: {
            scaleOptions: ['Fader', 'Linear'],
            fields: {names: 'string', controlNo: 'number'},
            fieldNames: {names: 'Name', controlNo: 'Control No.'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
        },
        altFader1: {
            scaleOptions: ['EQ', 'BEQ', 'Linear'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true
        },
        altFader2: {
            scaleOptions: ['EQ', 'BEQ', 'Linear'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true
        },
        altFader3: {
            scaleOptions: ['EQ', 'BEQ', 'Linear'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true
        },
        altToggle: {
            scaleOptions: ['Switch'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            name: true
        },
        mute: {
            scaleOptions: ['Switch'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            name: true
        },
        meter1: {
            scaleOptions: ['Meter'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            name: true
        },
        meter2: {
            scaleOptions: ['Meter'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            name: true
        },
        hg: {
            scaleOptions: ['Meter'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            name: true
        },
        control: {
            hideBank: true,
            fields: {
                names: 'string',
                controlNo: 'number',
                readOnly: 'checkbox',
                types: ['Switch', 'Meter'],
            },
            fieldNames: {
                names: 'Name',
                controlNo: 'Control No.',
                readOnly: 'Read Only',
                types: 'Scaling'
            },
        }
    },
    tesira: {
        faders: {
            scaleOptions: ['level'],
            fields: {names: 'string', instanceId: 'string', index: 'number'},
            fieldNames: {names: 'Name', instanceId: 'Instance ID', index: 'Index'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            tesiraRate: true,
        },
        altFader1: {
            scaleOptions: ['gain'],
            fields: {instanceId: 'string', index: 'number'},
            fieldNames: {instanceId: 'Instance ID', index: 'Index'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true,
            tesiraRate: true,
        },
        altFader2: {
            scaleOptions: ['gain'],
            fields: {instanceId: 'string', index: 'number'},
            fieldNames: {instanceId: 'Instance ID', index: 'Index'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true,
            tesiraRate: true,
        },
        altFader3: {
            scaleOptions: ['gain'],
            fields: {instanceId: 'string', index: 'number'},
            fieldNames: {instanceId: 'Instance ID', index: 'Index'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true,
            tesiraRate: true,
        },
        altToggle: {
            scaleOptions: ['state'],
            fields: {instanceId: 'string', index: 'number'},
            fieldNames: {instanceId: 'Instance ID', index: 'Index'},
            name: true,
            tesiraRate: true,
        },
        mute: {
            scaleOptions: ['mute'],
            fields: {instanceId: 'string', index: 'number'},
            fieldNames: {instanceId: 'Instance ID', index: 'Index'},
            name: true,
            tesiraRate: true,
        },
        meter1: {
            scaleOptions: ['level'],
            fields: {instanceId: 'string', index: 'number'},
            fieldNames: {instanceId: 'Instance ID', index: 'Index'},
            name: true,
            tesiraRate: true,
        },
        meter2: {
            scaleOptions: ['level'],
            fields: {instanceId: 'string', index: 'number'},
            fieldNames: {instanceId: 'Instance ID', index: 'Index'},
            name: true,
            tesiraRate: true,
        },
        hg: {
            scaleOptions: ['gainReduction'],
            fields: {instanceId: 'string', index: 'number'},
            fieldNames: {instanceId: 'Instance ID', index: 'Index'},
            name: true,
            tesiraRate: true,
        },
        control: {
            hideBank: true,
            fields: {
                names: 'string',
                instanceId: 'string',
                index: 'number',
                readOnly: 'checkbox',
                types: ['state', 'meter']
            },
            fieldNames: {
                names: 'Name',
                instanceId: 'Instance ID',
                index: 'Index',
                readOnly: 'Read Only',
                types: 'Scaling'
            },
            tesiraRate: true,
        }
    },
    'q-sys': {
        faders: {
            scaleOptions: ['Fader', 'Linear'],
            fields: {names: 'string', controlNo: 'number'},
            fieldNames: {names: 'Name', controlNo: 'Control No.'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
        },
        altFader1: {
            scaleOptions: ['EQ', 'BEQ', 'Linear'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true
        },
        altFader2: {
            scaleOptions: ['EQ', 'BEQ', 'Linear'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true
        },
        altFader3: {
            scaleOptions: ['EQ', 'BEQ', 'Linear'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true
        },
        altToggle: {
            scaleOptions: ['Switch'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            name: true
        },
        mute: {
            scaleOptions: ['Switch'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            name: true
        },
        meter1: {
            scaleOptions: ['Meter'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            name: true
        },
        meter2: {
            scaleOptions: ['Meter'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            name: true
        },
        hg: {
            scaleOptions: ['Meter'],
            fields: {controlNo: 'number'},
            fieldNames: {controlNo: 'Control No.'},
            name: true
        },
        control: {
            hideBank: true,
            fields: {
                names: 'string',
                controlNo: 'number',
                readOnly: 'checkbox',
                types: ['Switch', 'Meter']
            },
            fieldNames: {
                names: 'Name',
                controlNo: 'Control No.',
                readOnly: 'Read Only',
                types: 'Scaling'
            },
        }
    },
    xilica: {
        faders: {
            scaleOptions: ['Fader'],
            fields: {names: 'string', objectName: 'string'},
            fieldNames: {names: 'Name', objectName: 'Obj name'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
        },
        altFader1: {
            scaleOptions: ['Shelf'],
            fields: {objectName: 'string'},
            fieldNames: {objectName: 'Obj name'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true
        },
        altFader2: {
            scaleOptions: ['Shelf'],
            fields: {objectName: 'string'},
            fieldNames: {objectName: 'Obj name'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true
        },
        altFader3: {
            scaleOptions: ['Shelf'],
            fields: {objectName: 'string'},
            fieldNames: {objectName: 'Obj name'},
            minGain: true,
            maxGain: true,
            dspMinGain: true,
            name: true
        },
        altToggle: {
            scaleOptions: ['Button'],
            fields: {objectName: 'string'},
            fieldNames: {objectName: 'Obj name'},
            name: true
        },
        mute: {
            scaleOptions: ['Button'],
            fields: {objectName: 'string'},
            fieldNames: {objectName: 'Obj name'},
            name: true
        },
        meter1: {
            scaleOptions: ['Meter'],
            fields: {objectName: 'string'},
            fieldNames: {objectName: 'Obj name'},
            name: true
        },
        meter2: {
            scaleOptions: ['Meter'],
            fields: {objectName: 'string'},
            fieldNames: {objectName: 'Obj name'},
            name: true
        },
        hg: {
            scaleOptions: ['HG','LED'],
            fields: {objectName: 'string'},
            fieldNames: {objectName: 'Obj name'},
            name: true
        },
        control: {
            hideBank: true,
            fields: {
                names: 'string',
                objectName: 'number',
                readOnly: 'checkbox',
                types: ['Button', 'LED']
            },
            fieldNames: {
                names: 'Names',
                objectName: 'Obj name',
                readOnly: 'Read Only',
                types: 'Scaling'
            },
        }
    }
};

const tabsIndex = [
    'faders', 'altFader1', 'altFader2', 'altFader3', 'altToggle', 'mute', 'meter1', 'meter2', 'hg', 'control'
];

let interval = null;
export const SiteSetup = () => {
    const dispatch = useDispatch();

    const dataErrors = useSelector(getErrors);
    const status = useSelector(getStatus);
    const data = useSelector(getSiteSetup);


    const [disabled, setDisabled] = useState(true);
    const [bank, setBank] = useState('EM4');
    const [dspType, setDspType] = useState('q-sys');
    //const [selectedTab, setSelectedTab] = useState(headerTableName['q-sys']);
    const [selectedTab, setSelectedTab] = useState(tabsOptions[dspType]);
    const [notice, setNotice] = useState('');
    const [error, setError] = useState('');
    const [fieldValidation, setFieldValidation] = useState(true);
    const [inputHeaderValidation, setInputHeaderValidation] = useState(true);
/*
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    });*/

    useEffect(() => {
        if (data.DspType === undefined) {
            return;
        }

        setDspType(data.DspType.name.toLowerCase());
        setSelectedTab(tabsOptions[dspType]);

    }, [dspType, data]);


    useEffect(() => {
        // check model for Bank field on Status page
        switch (status.model) {
            case 'EM4':
                setBank(4);
                break;
            case 'EM8':
                setBank(8);
                break;
            case 'EM12':
                setBank(12);
                break;
            default:
                setBank('model not found');
                break;
        }
    }, [status]);


    useEffect(() => {
        dispatch(siteSetupActions.getSiteSetupAsync());
        dispatch(statusActions.getStatusAsync());
        dispatch(errorsActions.getErrorsAsync());

        dispatch(siteSetupActions.getQsysAsync());
        dispatch(siteSetupActions.getSymetrixAsync());
        dispatch(siteSetupActions.getTesiraAsync());
        dispatch(siteSetupActions.getXilicaAsync());

        interval = setInterval(() => {
            dispatch(errorsActions.getErrorsAsync());
        }, 1000);

        if (!disabled) clearInterval(interval);
        return () => clearInterval(interval);
    }, [dispatch, disabled]);


    useEffect(() => {
        if (Object.keys(data).length === 0) return;
        if (data.SiteName !== '' && data.DspID !== '') {
            setFieldValidation(true)
        } else {
            setFieldValidation(false)
        }

    }, [data]);

    if (data.DspType === undefined) {
        return false;
    }

    const editingData = () => {
        setDisabled(!disabled)
    };

    const cancelingData = async () => {
        if(data.SiteName === 'Tesira 1') {
            data.SiteName = 'Tesira'   
        }
        setDisabled(!disabled);
        dispatch(siteSetupActions.setSiteSetup({}));
        dispatch(siteSetupActions.getSiteSetupAsync());

    };

    // Rectifies types discrepencies when canceling data
    const toggleData = (newDSP) => {
        setTimeout(() => {
        dispatch(siteSetupActions.setSiteSetup({newDSP}));
        dispatch(siteSetupActions.getSiteSetupAsync());
    }, 50);
    }

    const savingData = async () => {
        setDisabled(!disabled);
        // console.log({...data})
        await server.setSiteSetup(({...data}))
            .then((response) => {
                if (response.status === 200) {
                    console.log('data saved successfully')
                    setNotice('The data was saved successfully');
                    setTimeout(() => {
                        setNotice('');
                    }, 3000);
                }
            })
            .catch(() => {
                setError('The internet connection has timed out');
                setTimeout(() => {
                    setError('');
                }, 3000);
            });

        dispatch(mysteryActions.setShowPopup(true));
        setTimeout(() => {
            dispatch(mysteryActions.setShowPopup(false));
        }, 1000)
    };

    const downloadingConfig = (el) => {
        const file = "text/json;charset=utf-8," +
            encodeURIComponent(
                JSON.stringify([data, {model: status.model}, {errors: dataErrors}]));
        el.target.setAttribute("href", "data:" + file);
        el.target.setAttribute("download", "sitesetup.json");
    };

    const importing = (e) => {
        const file = e.target.files[0];
        if (file.length !== 0) {

            // validation resolution file
            if (file.type === 'application/json') {
                const handleFileLoad = async (e) => {
                    await server.setSiteSetup(e.target.result)
                    .then( window.location.reload());

                };
                const reader = new FileReader();
                reader.onload = handleFileLoad;
                reader.readAsText(file);
            } else {
                alert('please upload ".json" file');
            }
        }
    };


    return (
        <section className={styles.siteSetup}>
            <div className={styles.siteSetupTitle}>
                <h2>
                    Site Setup
                </h2>
                <button type="button" className={styles.primaryBtn} onClick={() => editingData()}
                        disabled={!disabled}>Edit
                </button>
                <div className="notice">{notice}</div>
                <div className="error">{error}</div>
            </div>
            <div className={styles.siteSetupInner}>
                <DspTable
                    data={data}
                    fields={DSP}
                    dspType={dspType}
                    setDspType={setDspType}
                    passEdit={() => toggleData()}
                    disabled={disabled}/>
                <div className={styles.siteSetupButtons}>
                    <button
                        type="button"
                        className={fieldValidation && inputHeaderValidation ? styles.primaryBtn
                            : styles.primaryBtn + ' ' + styles.disabled}
                        onClick={() => savingData()}
                        disabled={disabled}>Save
                    </button>
                    <button type="button" className={styles.primaryBtn} disabled={disabled}
                            onClick={() => cancelingData()}>Cancel
                    </button>
                    {disabled &&
                    (<>
                        <a href="/" className={styles.primaryBtn}
                           onClick={(el) => downloadingConfig(el)}>Download Backup</a>
                        <label htmlFor="import" className={styles.primaryBtn}>Import to Page</label>
                        <input id='import' type="file" onChange={(e) => importing(e)}/>
                    </>)}

                </div>
            </div>

            {
            [data].map((item, index) => (
                <Tabs key={index} className={styles.siteSetupTabs}>
                    <TabList className={styles.siteSetupTabs}>
                        <Tab key='0'>Faders</Tab>
                        <Tab key='1'>Alternate Faders 1</Tab>
                        <Tab key='2'>Alternate Faders 2</Tab>
                        <Tab key='3'>Alternate Faders 3</Tab>
                        <Tab key='4'>Alternate Toggles</Tab>
                        <Tab key='5'>Mutes</Tab>
                        <Tab key='6'>Meters 1</Tab>
                        <Tab key='7'>Meters 2</Tab>
                        <Tab key='8'>Highest Gain</Tab>
                        <Tab key='9'>Control Toggles</Tab>
                    </TabList>
                    {tabsIndex.map((keys, index) => {
                        return (
                            <TabPanel key={index}>
                                <SiteSetupTable
                                    keys={keys}
                                    dspType={dspType}
                                    array={item}
                                    errors={dataErrors}
                                    options={tabsOptions[dspType][keys]}
                                    bank={bank}
                                    disabled={disabled}
                                    setInputHeaderValidation={setInputHeaderValidation}/>
                            </TabPanel>)
                    })}
                </Tabs>
            ))}
        </section>
    );
};

